import React from 'react'

export const SEO = () => {
    return (
        <div id='SEO_component'>
            <div id='SEO_component--title'>
                <p>SEARCH ENGINE</p>
                <p id='seo_subheader'>OPTIMIZATION</p>
            </div>
            <div className='SEO_services'>
                <div>
                    <img alt='audit.png' src='https://i.imgur.com/J5jvj9S.png' ></img>
                    <p>Full Audit to address best avenues of success</p>
                </div>
                <div>
                    <img alt='linkbuilding.png' src='https://i.imgur.com/r9YOC1F.png' ></img>
                    <p>Link Building so your business is chosen by customers more often</p>
                </div>
            </div>
            <div className='SEO_services'>
                <div>
                    <img alt='keywords.png' src='https://i.imgur.com/3UORuK5.png' ></img>
                    <p>24/7 Data Driven Keyword Research to keep you up to date</p>
                </div>
                <div>
                    <img alt='competitoraudits.png' src='https://i.imgur.com/SwpMmuE.png' ></img>
                    <p>Full-Scale Competitor Audits so you're always one step ahead</p>
                </div>
            </div>
        </div>
    )
}