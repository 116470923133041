import React from 'react'

export const DigitalMarketingService = (props) => {
    return (
        <div className='DigitalMarketing_service'>
            <img alt={props.alt} className='DigitalMarketing_service--img' src={props.src}></img>
            <div className='DigitalMarketing_container--text'>
                <p className='DigitalMarketing--text'>{props.text}</p>
            </div>
        </div>
    )
}