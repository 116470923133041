import React from 'react'
import { DandCService } from './DandCService'
import { content } from '../content/content'
import { Link } from 'gatsby'

export const DesignAndCreation = () => {
    return (
        <div id='designAndCreation_component'>
            <div id='designAndCreation_component--title'>
                <div className='services_component--seperator'></div>
                <p className='services_title--header'>WEBSITE</p>
                <p className='services_title--subheader'>DESIGN AND CREATION</p>   
            </div>
            <div id='DandCService_container'>
                <DandCService alt='service1img' src={content.DandCService1.img} textEmphasis={content.DandCService1.textEmphasis} textBody2={content.DandCService1.textBody2} />
                <DandCService alt='service2img' src={content.DandCService2.img} textEmphasis={content.DandCService2.textEmphasis} textBody1={content.DandCService2.textBody1} />
                <DandCService alt='service3img' src={content.DandCService3.img} textBody1={content.DandCService3.textBody1} textEmphasis={content.DandCService3.textEmphasis} textBody2={content.DandCService3.textBody2} />
                <DandCService alt='service4img' src={content.DandCService4.img} textEmphasis={content.DandCService4.textEmphasis} textBody2={content.DandCService4.textBody2} />
            </div>
            <Link className='learnMore_button' to='/WebDesign/'><button>Learn More</button></Link>
        </div>
    )
}

