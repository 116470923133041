import React from 'react'
import { DigitalMarketingService } from './DigitalMarketingService'
import { content } from '../content/content'

export const DigitalMarketing = () => {
    return (
        <div>
            <div id='digitalMarketing_component--title' >
                <div className='services_component--seperator'></div>
                <p className='services_title--header'>DIGITAL</p>
                <p className='services_title--subheader'>MARKETING</p>
            </div>
            <DigitalMarketingService alt='marketingservice1img' text={content.DigitalMarketingService1.text} src={content.DigitalMarketingService1.src}></DigitalMarketingService>
            <DigitalMarketingService alt='marketingservice2img' text={content.DigitalMarketingService2.text} src={content.DigitalMarketingService2.src}></DigitalMarketingService>
            <DigitalMarketingService alt='marketingservice3img' text={content.DigitalMarketingService3.text} src={content.DigitalMarketingService3.src}></DigitalMarketingService>
            <DigitalMarketingService alt='marketingservice4img' text={content.DigitalMarketingService4.text} src={content.DigitalMarketingService4.src}></DigitalMarketingService>
        </div>
    )
}