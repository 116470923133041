import React from 'react'
import { DesignAndCreation } from '../components/DesignAndCreation' 
import { SEO } from '../components/SEO'
import { DigitalMarketing } from '../components/DigitalMarketing'
import Layout from '../components/layout'
import { Link } from 'gatsby'

export default function ApproachPage() {
    return (
        <Layout>
            <div id='services_page'>
                <div id='services_component--ourServices'>
                    <div id='ourServices--body'>
                        <p id='ourServices--title'>OUR APPROACH</p>
                        <p id='ourServices--text'>We take great pride in offering Chattanoogans advanced marketing solutions that guarantee successful outcomes for your business.</p>
                    </div>
                    <img id='approachBanner' alt='ourapproachbanner.png' src='https://i.imgur.com/aWgiMHR.png'></img>
                    <div className='approachButtons'>
                        <Link to='/Pricing/'><button>Get Started</button></Link>
                        <Link to='/Services/'><button>Learn More</button></Link>
                    </div>
                </div>
                <DesignAndCreation />
                <SEO />
                <Link className='learnMore_button' to='/SEO/'><button>Learn More</button></Link>
                <DigitalMarketing />
                <Link className='learnMore_button' to='/DigitalMarketing/'><button>Learn More</button></Link>
            </div>
        </Layout>
    )
}